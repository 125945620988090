import { useEffect } from "react";
import PlusIcon from "../../../assets/icons/PlusIcon";
import useInventory from "../../../hooks/api/useInventory";
import Table from "../Table";
import usePagination from "../../../hooks/usePagination";
import WithImage from "../WithImage";
import Status from "../../Status";
import statuses from "../../../utils/statuses";
import View from "../View";
import { path } from "../../../utils/path";
import { formatNumber } from "../../../utils/numbers";
import RequestMaterials from "../../RequestMaterials";
import useModal from "../../../hooks/useModal";
import { requestTypes } from "../../../utils/vars";
import ViewIcon from "../../../assets/icons/ViewIcon";
import { useNavigate } from "react-router-dom";

const tableHead = [
  {
    title: "Item",
    target: ["material.imageUrl", "material.name"],
    render: (values) => <WithImage text={values[1]} imageUrl={values[0]} />,
  },
  {
    title: "Price",
    target: "material.price",
  },
  {
    title: "Category",
    target: "material.category.name",
  },
  {
    title: "Quantity",
    target: ["quantity", "material.unitOfMeasure"],
    render: (v) => formatNumber(v[0], "") + " " + v[1],
  },
  {
    title: "Condition",
    target: ["quantity"],
    render: (v) => {
      const status = v[0] ? statuses.info : statuses.warn;
      return (
        <Status
          text={v[0] ? "Good" : "not available"}
          className={`${status}-text ${status}-light`}
        />
      );
    },
  },
  {
    title: "Status",
    target: "quantity",
    render: (v) => {
      const classN =
        v > 10 ? statuses.active : v > 5 ? statuses.warn : statuses.danger;
      return (
        <Status
          text={v > 10 ? "available" : v > 5 ? "low on stock" : "out of stock"}
          className={`${classN}-light ${classN}-text`}
        />
      );
    },
  },
  {
    title: "Action",
    target: ["material", "quantity"],
    render: (v) => (
      <View
        link={`${path.materials.url}/${v[0]?._id}`}
        state={{ ...v[0], quantity: v[1] }}
      />
    ),
  },
];

function InventoryTable({ initialLimit, initialPage, outlet }) {
  const { getProducts, loading, products, pagination: stat } = useInventory();
  const { setPageNumber, pageNumber, limit } = usePagination({
    initialLimit,
    initialPage,
  });
  const { isOpened, openModal, closeModal } = useModal();
  const navigate = useNavigate();
  useEffect(() => {
    if (outlet) {
      getProducts(outlet, pageNumber, limit);
    }
    // eslint-disable-next-line
  }, [pageNumber, limit, outlet]);

  return (
    <>
      <RequestMaterials
        isOpened={isOpened}
        closeModal={closeModal}
        type={requestTypes.supply}
        outlet={outlet}
      />
      <Table
        loading={loading}
        head={tableHead}
        totalCount={stat.total}
        data={products}
        limit={limit}
        onPaginate={setPageNumber}
        btn1={{
          disabled: !outlet,
          icon: <PlusIcon />,
          onClick: openModal,
          title: "Request Supply",
        }}
        btn2={{
          disabled: !outlet,
          icon: <ViewIcon color="var(--main-yellow)" />,
          title: "Sent Requests",
          onClick: () =>
            navigate(path.inventory.requests.url + `?outlet=${outlet}`),
        }}
        title="Inventory"
      />
    </>
  );
}

export default InventoryTable;
