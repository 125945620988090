import { useLocation } from "react-router-dom";
import InfoText from "../../../components/TextInfo";
import WithLink from "../../../components/table/WithLink";
import { formatDateTime, months } from "../../../utils/date";
import Status from "../../../components/Status";
import { baseStatuses } from "../../../utils/statuses";
import icart from "../../../assets/icart.png";
import Button from "../../../components/Button";
import Pen from "../../../assets/icons/Pen";
import Creator from "../../../components/Creator";
import { getRandomImage } from "../../../utils/string";
import maryama from "../../../assets/maryama.jpg";
import Link from "../../../assets/icons/Link";
import RecentSupplyToICart from "../../../components/table/withdata/RecentSupplyToICart";
import RecentSalesOnICart from "../../../components/table/withdata/RecentSalesOnICart";
import Revenue from "../../../components/charts/Revenue";
import { COLORS } from "../../../utils/colors";
import { useContext } from "react";
import AppContext from "../../../contexts/AppContext";

const concepts = [
  { name: "Masa", image: getRandomImage() },
  { name: "Cow tail & legs pepper soup", image: getRandomImage() },
  { name: "Beanfry", image: getRandomImage() },
];
const operators = [
  { name: "Ismail Dalhatu", image: getRandomImage() },
  { name: "Usman Dalhatu", image: getRandomImage() },
  { name: "Abubakar Sadiq Dalhatu", image: getRandomImage() },
];

function ViewICart(props) {
  const { formatCurrency } = useContext(AppContext);
  const { state } = useLocation();

  return (
    <div>
      <h2 className="text-primary fs-24 f-700">View iCart</h2>
      <br />
      <div className="d-flex flex-wrap justify-between fields-list">
        <InfoText className="full-flex" title="iCart ID:" text={"state.id"} />
        <InfoText className="full-flex" title="User:">
          <WithLink
            text={`${state.customer.firstName} ${state.customer.lastName}`}
          />
        </InfoText>
        <InfoText
          className="full-flex"
          text={formatCurrency(85776543)}
          title="Total Revenue:"
        />
        <InfoText className="full-flex" title="Phone Number:">
          <WithLink className="text-no-wrap" text="+234 902 2314 973" />
        </InfoText>
        <InfoText className="full-flex" title="Current Location:">
          <WithLink className="text-no-wrap" text={state.location.label} />
        </InfoText>
        <InfoText
          className="full-flex"
          title={`Date ${
            "state.status" === "purchased" ? "Purchased" : "Subscribed"
          }:`}
          text={formatDateTime(state.createdAt).date}
        />
        <InfoText className="full-flex" title="Status:">
          <Status className={baseStatuses[state.status]} text={state.status} />
        </InfoText>
      </div>

      <br />
      <div className="d-flex tab-column gap-10 flex-wrap">
        <div className="map-container d-flex">
          <Revenue
            datasets={[
              {
                label: "",
                data: months
                  .map((a) => a.slice(0, 3))
                  .map(() => Math.random() * 1000),
                borderColor: COLORS.MAIN.DARK,
                backgroundColor: COLORS.MAIN.DARK,
              },
              // {
              //   label: "",
              //   data: months
              //     .map((a) => a.slice(0, 3))
              //     .map(() => Math.random() * 1000),
              //   borderColor: COLORS.BLUE_TITNTS_SHADES.B_05,
              //   backgroundColor: COLORS.BLUE_TITNTS_SHADES.B_05,
              // },
            ]}
            labels={months}
            height="100%"
            title="iCart Revenue"
          />
        </div>
        <div style={{ flex: 0.35 }}>
          <img
            alt="iCart"
            style={{ width: "100%", objectFit: "cover", cursor: "pointer" }}
            src={icart}
            // alt={state.id}
          />
        </div>
      </div>
      <br />
      <div className="cards flex-wrap d-flex">
        <div className="card">
          <div className="d-flex justify-between align-center">
            <h2 className="raleway fs-18 f-700 text-primary">Primary Brand</h2>
            <Button
              fontSize="fs-12"
              title="Change"
              outline
              rSvg={<Pen />}
              height={34}
              width={75}
            />
          </div>
          <br />
          <Creator text="Maryam Adam Hamza" />
          <img
            alt="Maryama's Kitchen"
            src={maryama}
            style={{
              width: "100%",
              height: 200,
              objectFit: "cover",
              borderRadius: 8,
            }}
          />
        </div>
        <div className="card">
          <div className="d-flex justify-between align-center">
            <h2 className="raleway fs-18 f-700 text-primary">Concepts</h2>
            <Button
              fontSize="fs-12"
              title="Edit"
              outline
              rSvg={<Pen />}
              height={34}
              width={75}
            />
          </div>
          <br />
          {concepts.map((c, idx) => (
            <Creator key={idx} label="name" text={c.name} />
          ))}

          {/* <Table
            data={[
              {
                concept: "Masa",
                status: "active",
              },
            ]}
            showFooterAndHeader={false}
            tableWidth="100%"
            head={conceptHead}
          /> */}
        </div>
        <div className="card">
          <div className="d-flex justify-between align-center">
            <h2 className="raleway fs-18 f-700 text-primary">
              Active Operators
            </h2>
            <Button
              fontSize="fs-12"
              title="More"
              outline
              rSvg={<Link />}
              height={34}
              width={75}
            />
          </div>
          <br />
          {operators.map((c, idx) => (
            <Creator
              key={idx}
              label={`logged in at: 07:0${6 + idx}`}
              text={c.name}
              image={c.image}
            />
          ))}
        </div>
      </div>
      <br />
      <div className="d-flex gap-10 flex-wrap">
        <div style={{ width: "50%" }} className="full-flex">
          <RecentSupplyToICart hideFooter={true} width={0.49} />
        </div>
        <div style={{ width: "50%" }} className="full-flex">
          <RecentSalesOnICart hideFooter={true} width={0.49} />
        </div>
      </div>
      <br />
      <div
        style={{ maxWidth: 400, marginLeft: "auto" }}
        className="d-flex gap-10 mobile-column"
      >
        <Button className="bg-danger" title="Deactivate iCart" />
        <Button outline title="Edit iCart" />
      </div>
    </div>
  );
}

export default ViewICart;
