import Card from "../../../components/Card";
import Map from "../../../components/map/Map";
import Tab from "../../../components/Tab";
import { Route, Routes, useNavigate } from "react-router-dom";
import { path } from "../../../utils/path";
// import ICarts from "./ICarts";
import Locations from "./Locations";
import Outlets from "./Outlets";
import Cities from "./Cities";
import Countries from "./Countries";
import Suppliers from "./Suppliers";

const tableTabs = [
  // {
  //   title: "iCarts",
  //   to: path.management.icarts.url,
  // },
  {
    title: "Locations",
    to: path.management.locations.url,
  },
  {
    title: "Suppliers",
    to: path.management.suppliers.url,
  },
  {
    title: "Outlets",
    to: path.management.outlets.url,
  },
  // {
  //   title: "Layouts",
  //   to: path.management.layouts.url,
  // },
  {
    title: "Cities",
    to: path.management.cities.url,
  },
  {
    title: "Countries",
    to: path.management.countries.url,
  },
];

function Dashboard(props) {
  const navigate = useNavigate();
  const handleNavigation = (tab) => navigate(tab.to);
  return (
    <div>
      <div className="cards d-flex">
        <Card className="success-light" title={0} subTitle="Active Locations" />
        <Card className="success-light" title={0} subTitle="Active iCarts" />
        <Card className="warn-light" title={0} subTitle="Operating Cities" />
        <Card className="warn-light" title={0} subTitle="Operating Countries" />
        <Card
          className="success-light"
          title={0}
          subTitle="Active Operations Outlet"
        />
      </div>
      <br />
      <div className="d-flex tab-column gap-10 flex-wrap">
        <div className="map-container d-flex">
          <Map />
        </div>
        <div style={{ flex: 0.3 }}>
          <Card title={0} subTitle="Total Location" />
          <br />
          <Card title={0} subTitle="Total iCart" />
          <br />
          <Card subTitle="Total Outlets" title={0} />
        </div>
      </div>
      <br />
      <div>
        <Tab autoSelect canNavigate tabs={tableTabs} />
        <Routes>
          {/* <Route path={path.management.icarts.root} element={<ICarts />} /> */}
          <Route
            path={path.management.locations.root}
            element={<Locations />}
          />
          <Route path={path.management.outlets.root} element={<Outlets />} />
          {/* <Route path={path.management.layouts.root} element={<Layouts />} /> */}
          <Route path={path.management.cities.root} element={<Cities />} />
          <Route
            path={path.management.suppliers.root}
            element={<Suppliers />}
          />
          <Route
            path={path.management.countries.root}
            element={<Countries />}
          />
        </Routes>
      </div>
    </div>
  );
}

export default Dashboard;
