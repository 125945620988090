import Dashboard from "../pages/app/wallet/Dashboard";
import { Route, Routes } from "react-router-dom";
import { path } from "../utils/path";
import "../css/pages/wallet.css";

function Wallet(props) {
  return (
    <Routes>
      <Route path={path.root} element={<Dashboard />} />
    </Routes>
  );
}

export default Wallet;
