import InventoryContext from "../contexts/InventoryContext";
import Dashboard from "../pages/app/inventory/Dashboard";
import { Route, Routes } from "react-router-dom";
import { path } from "../utils/path";
import SentRequests from "../pages/app/inventory/SentRequests";
import SupplyRequestsDetails from "../pages/app/inventory/SupplyRequestsDetails";

function Inventory(props) {
  return (
    <InventoryContext.Provider value={{}}>
      <Routes>
        <Route path={path.root} element={<Dashboard />} />
        <Route path={path.inventory.requests.root} element={<SentRequests />} />
        <Route
          path={path.inventory.requests.view.root}
          element={<SupplyRequestsDetails />}
        />
      </Routes>
    </InventoryContext.Provider>
  );
}

export default Inventory;
