import { useState } from "react";
import Group from "../FlexGroup";
import Button from "../Button";
import Input from "../Input";

const Timing = ({
  remove,
  item,
  handleChange,
  id,
  time,
  incharge,
  process,
  placeholder = "Ingredient",
  options = [],
}) => {
  return (
    <div className="submit-menu-card">
      <br />
      <br />
      <div className="inputs">
        <Input
          value={item}
          onChange={(v) => handleChange(id, "item", v)}
          placeholder={placeholder}
          select
        />
        <Input
          value={process}
          onChange={(v) => handleChange(id, "process", v)}
          placeholder="Process"
        />
      </div>
      <br />
      <div className="inputs">
        <Input
          value={time}
          onChange={(v) => handleChange(id, "time", v)}
          placeholder="Time"
          time
          type="time"
        />
        <Input
          value={incharge}
          onChange={(v) => handleChange(id, "incharge", v)}
          placeholder="In Charge"
          select
          options={options}
        />
      </div>
      <br />
      <Button
        outline
        style={{ marginLeft: "auto" }}
        onClick={remove}
        borderColor="var(--alerts-danger)"
        title="Remove"
        color="danger-text"
        fontSize="fs-12"
        width={80}
        height={30}
      />
      <br />
    </div>
  );
};

const AddButton = (props) => (
  <Button
    {...props}
    style={{ marginLeft: "auto" }}
    title="Add Item"
    fontSize="fs-12"
    width={80}
    height={30}
  />
);

function PrepTimeTable(props) {
  const [instructions1, setInstructions1] = useState([{ id: 1 }]);
  const [instructions2, setInstructions2] = useState([{ id: 1 }]);
  return (
    <div>
      <span className="fs-14 d-block text-brown f-700">Prep Time Table</span>
      <br />
      <Group
        items={instructions1}
        setItems={setInstructions1}
        Element={Timing}
        AddElement={AddButton}
        options={[
          {
            label: "Chefs",
            value: "chefs",
          },
          {
            label: "Kitchen Asistant",
            value: "ka",
          },
        ]}
      />
      <span className="fs-14 d-block text-brown f-700">
        Distribution Time Table
      </span>
      <br />
      <Group
        items={instructions2}
        setItems={setInstructions2}
        Element={Timing}
        options={[
          {
            label: "Prep Room Driver",
            value: "driver",
          },
          {
            label: "Supervisor",
            value: "supervisor",
          },
        ]}
        placeholder="Item"
        AddElement={AddButton}
      />
    </div>
  );
}

export default PrepTimeTable;
