const ArrowDownIcon = ({ color = "#ffffff" }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 8 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.08556 7.08553L7.08556 5.99787L2.77735 5.99401L7.47125 1.30011L6.69986 0.52872L2.00596 5.22262L2.00982 0.914414L0.914443 0.914414V7.08553H7.08556Z"
        fill={color}
      />
    </svg>
  );
};

export default ArrowDownIcon;
