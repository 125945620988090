import { useEffect, useState } from "react";
import Input from "./Input";
import Modal from "./Modal";
import ItemCounter from "./ItemCounter";
import useInventory from "../hooks/api/useInventory";
import Loader from "./Loader";
import Image from "./Image";
import Button from "./Button";
import useConfig from "../hooks/api/useConfig";

function RequestMaterials({ isOpened, outlet, type = "", closeModal }) {
  const [search, setSearch] = useState("");
  const [items, setItems] = useState([]);
  const [note, setNote] = useState("");
  const { searchMaterial, sendMaterialsRequest, materials, loading } =
    useInventory();
  const { getUnits, units, loading: loading2 } = useConfig();

  const saveRequest = () =>
    sendMaterialsRequest(
      {
        items: items
          .filter((_) => _.quantity)
          .map((_) => ({
            material: _._id,
            supplyUnit: _.supplyUnit,
            requestedQuantity: _.quantity,
            priority: _.priority || "moderate",
          })),
        note,
      },
      outlet,
      () => {
        setItems([]);
        closeModal();
      }
    );

  useEffect(() => {
    searchMaterial(search);
    // eslint-disable-next-line
  }, [search]);

  useEffect(() => {
    getUnits();
    // eslint-disable-next-line
  }, []);

  return (
    <Modal className="min-600" isVisible={isOpened}>
      <h2 className="fs-24 raleway f-700 text-primary">Request {type}</h2>
      <br />
      <Input placeholder="Search item" value={search} onChange={setSearch} />
      <br />
      {loading || loading2 ? (
        <div
          style={{
            width: "100%",
            marginBottom: 10,
            marginTop: 10,
          }}
          className="d-flex justify-center align-center"
        >
          <Loader loading />
        </div>
      ) : null}
      <div className="search-rusults">
        {materials
          // eslint-disable-next-line
          .filter((_) => !items.find((xx) => xx._id == _._id))
          .map((_, idx) => (
            <div className="material-search-item d-flex align-center" key={idx}>
              <Image src={_.imageUrl} />
              <div>
                <span className="d-block fs-16 f-600 text-brown">{_.name}</span>
                <span className="d-block fs-10">{_.tags.join(", ")}</span>
              </div>
              <Button
                onClick={() => {
                  setItems((items) => [{ ..._, quantity: 1 }, ...items]);
                  setSearch("");
                }}
                title="Add"
                width={70}
                height={25}
                fontSize="fs-10"
                className="bg-brown"
              />
            </div>
          ))}
      </div>
      {items.map((_, idx) => (
        <ItemCounter
          units={units}
          showUnit
          input
          item={_}
          updateItems={setItems}
          addOption={false}
          key={idx}
        />
      ))}
      <br />
      <Input value={note} onChange={setNote} placeholder="Note" textarea />
      <br />
      <div className="d-flex gap-10">
        <Button onClick={closeModal} title="Cancel" outline width={110} />
        <Button
          disabled={!items.length}
          loading={loading}
          onClick={saveRequest}
          title="Send Request"
        />
      </div>
    </Modal>
  );
}

export default RequestMaterials;
