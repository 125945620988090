import Status from "../../../components/Status";
import ID from "../../../components/table/ID";
import Table from "../../../components/table/Table";
import View from "../../../components/table/View";
import WithImage from "../../../components/table/WithImage";
import statuses from "../../../utils/statuses";
import useConcepts from "../../../hooks/api/useConcepts";
import usePagination from "../../../hooks/usePagination";
import { useEffect } from "react";
import { path } from "../../../utils/path";
import useQuery from "../../../hooks/useQuery";

const tableHead = [
  {
    title: "#",
    target: "#",
  },
  {
    title: "Code",
    target: "_id",
    render: (id) => (
      <ID
        link={path.creators.edit.url + id}
        id={id.split("").reverse().join("").slice(0, 6).toUpperCase()}
        label="Edit Concept"
      />
    ),
  },
  {
    title: "Name",
    target: ["logo", "name"],
    render: (values) => <WithImage text={values[1]} imageUrl={values[0]} />,
  },
  {
    title: "Creator",
    target: [
      "brand.creator.firstName",
      "brand.creator.lastName",
      "brand.creator.profileImage",
    ],
    render: (values) => (
      <WithImage text={`${values[0]} ${values[1]}`} imageUrl={values[2]} />
    ),
  },
  {
    title: "Total Score",
    target: "totalScore",
  },
  {
    title: "Rating",
    target: "rating",
  },
  {
    title: "Description",
    target: "description",
  },
  {
    title: "Status",
    target: "status",
    render: (v) => (
      <Status
        light
        className={
          v === "rejected"
            ? statuses.danger
            : v === "pending"
            ? statuses.warn
            : v === "active"
            ? statuses.active
            : statuses.info
        }
        text={v}
      />
    ),
  },
  {
    title: "Active",
    target: "active",
    render: (v) => (
      <Status
        light
        className={!v ? statuses.danger : statuses.info}
        text={v ? "active" : "not active"}
      />
    ),
  },
  {
    title: "Actions",
    target: "id",
    render: (v) => <View />,
  },
];

function Concepts(props) {
  // eslint-disable-next-line
  const { getAllConcepts, allConcepts, loading } = useConcepts();
  const { limit, pageNumber, setPageNumber } = usePagination({
    initialLimit: 5,
  });
  const query = useQuery();

  useEffect(() => {
    getAllConcepts(pageNumber, limit, query.get("status"));
    // eslint-disable-next-line
  }, [pageNumber, limit, query.get("status")]);

  return (
    <div>
      <Table
        loading={loading}
        limit={limit}
        totalCount={203}
        data={allConcepts}
        head={tableHead}
        title="Concepts"
        onPaginate={setPageNumber}
      />
    </div>
  );
}

export default Concepts;
