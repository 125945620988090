import { useEffect, useState } from "react";
import NotFound from "../../../components/NotFound";
import useQuery from "../../../hooks/useQuery";
import useAccounts from "../../../hooks/api/useAccounts";
import Loader from "../../../components/Loader";
import Card from "../../../components/Card";
import Image from "../../../components/Image";
import Status from "../../../components/Status";
import { accountStatuses, baseStatuses } from "../../../utils/statuses";
import StarsReview from "../../../components/StarsReview";
import Button from "../../../components/Button";
import MessagesIcon from "../../../assets/icons/MessagesIcon";
import NotificationIcon from "../../../assets/icons/NotificationIcon";
import Tab from "../../../components/Tab";
import { formatDateTime } from "../../../utils/date";
import AccountBasicDetails from "../../../components/account/AccountBasicDetails";
import AccountWallet from "../../../components/account/AccountWallet";

const tabs = [
  {
    title: "Account Details",
    Elemet: AccountBasicDetails,
  },
  {
    title: "Account Wallet",
    Elemet: AccountWallet,
  },
];

function AccountDetails(props) {
  const [currentTab, setCurrentTab] = useState(tabs[0]);
  const { getAccount, loading, account } = useAccounts();
  const { Elemet } = currentTab;
  const q = useQuery();
  const id = q.get("id");
  //   const account = {
  //     google: {
  //       id: null,
  //       authEnabled: false,
  //     },
  //     address: {
  //       country: null,
  //       city: null,
  //       street: null,
  //       state: null,
  //       zip: null,
  //     },
  //     verifications: {
  //       phone: false,
  //       email: false,
  //       identity: false,
  //     },
  //     _id: "6724aa8593f9ddedc8c59f17",
  //     firstName: "John",
  //     lastName: "Rider",
  //     email: "john@geticart.com",
  //     partner: null,
  //     role: "rider",
  //     status: "restricted",
  //     profileImage: "b822fc2a7fd5b0f6cb83b724be3a390e.jpg",
  //     phoneNumber: "0000000",
  //     devicePushToken: null,
  //     createdAt: "2024-11-01T10:16:37.175Z",
  //     updatedAt: "2024-11-14T23:06:06.624Z",
  //     __v: 0,
  //   };

  useEffect(() => {
    if (id) getAccount(id);
  }, []);

  if (!id) return <NotFound />;
  if (loading)
    return (
      <div className="full-loader d-flex align-center justify-center">
        <Loader loading />
      </div>
    );
  if (account)
    return (
      <div>
        <div className="d-flex user-info">
          <div className="info-1">
            <Image className="profile-img" src={account.profileImage} />
            <br />
            <br />
            <span className="fs-11 text-grey d-block f-400">
              Date Registered
            </span>
            <h3 className="text-brown">
              {formatDateTime(account.createdAt).dateTime}
            </h3>
            <br />
            <span className="fs-11 text-grey d-block f-400">Last Updated</span>
            <h3 className="text-brown">
              {formatDateTime(account.updatedAt).dateTime}
            </h3>
            <br />
            <span className="fs-11 text-grey d-block f-400">
              Account Status
            </span>
            <h3 className="text-brown b-text">{account.status}</h3>
            <br />
            <span className="fs-11 text-grey d-block f-400">Push Token</span>
            <h3 className="text-brown b-text">
              {account.devicePushToken || "-"}
            </h3>
          </div>
          <div className="info-2 full-flex">
            <h2 className="text-brown fs-32">
              {account.firstName} {account.lastName}
            </h2>
            <Status
              light
              text={account.role}
              className={baseStatuses[account.role]}
            />
            <br />
            <br />
            <span className="fs-14 d-block text-brown f-700">Rating</span>
            <br />
            <div className="d-flex gap-10 align-center">
              <h2 className="text-brown">4.5</h2>
              <StarsReview showNumber={false} defaultRating={4} />
            </div>
            <br />
            <div>
              <Button
                outline
                width={200}
                lSvg={<MessagesIcon color="var(--main-yellow)" />}
                title="Send notification"
              />
            </div>
            <br />
            <Tab
              autoSelect
              className="tabs-noborder"
              tabs={tabs}
              onSwitch={setCurrentTab}
            />
            <br />
            <br />
            {<Elemet account={account} />}
          </div>
        </div>
      </div>
    );
}

export default AccountDetails;
