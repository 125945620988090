import { Route, Routes } from "react-router-dom";
import Inventory from "./Inventory";
import { path } from "../utils/path";
import NavBar from "../components/NavBar";
import Header from "../components/Header";
import Sales from "./Sales";
import AppContext from "../contexts/AppContext";
import { useContext, useEffect, useState } from "react";
import Support from "./Support";
import Wallet from "./Wallet";
import Logout from "../pages/auth/Logout";
import NotFound from "../components/NotFound";
import Materials from "./Materials";
import Management from "./Management";
import "../css/pages/index.css";
import ConceptsAndCreators from "./Concepts";
import Businesses from "./Businesses";
import Accounts from "./Accounts";
import Stores from "./Store";
import Suppliers from "./Suppliers";
import Creators from "./Creators";
import Kitchens from "./Kitchens";
import Operators from "./Operators";
import AuthContext from "../contexts/AuthContext";
import BaseHome from "../pages/app/Home";
import Home from "../pages/app/staffs/Home";
import useConfig from "../hooks/api/useConfig";
import Loader from "../components/Loader";
import Dev from "../components/Dev";
import { formatIntCurrency } from "../utils/numbers";
import useQuery from "../hooks/useQuery";
import store from "../utils/store";

function Base(props) {
  const [isCollapsed, setIsCollapsed] = useState(true);
  // const [isCollapsed, setIsCollapsed] = useState(window.innerWidth < 760);
  const [region, setRegion] = useState({ symbol: "₦" });
  const [currentCity, setCurrentCity] = useState(null);
  const { user } = useContext(AuthContext);
  const storedOutlet = store.getItem("outlet");
  const query = useQuery();
  const {
    addCountry,
    addCity,
    cities,
    countries,
    loading,
    getCities,
    updateCity,
    getCountries,
    updateCountry,
  } = useConfig();

  useEffect(() => {
    getCountries();
    getCities((data) => {
      if (data.length) {
        if (storedOutlet)
          setCurrentCity(
            data.find((_) => _._id === storedOutlet.location.city)
          );
        else setCurrentCity(data[data.length - 1]);
      }
    });

    // eslint-disable-next-line
  }, []);

  if (loading)
    return (
      <div className="page d-flex align-center justify-center">
        <Loader loading />
      </div>
    );

  return (
    <AppContext.Provider
      value={{
        isCollapsed,
        setIsCollapsed,
        region,
        setRegion,
        addCountry,
        addCity,
        cities,
        countries,
        loading,
        updateCity,
        currentCity,
        updateCountry,
        setCurrentCity,
        outlet:
          query.get("outlet") || storedOutlet?._id || user?.staff?.outlet?._id,
        formatCurrency: (amount) =>
          formatIntCurrency(
            amount || 0,
            `en-${currentCity?.country?.code}`,
            currentCity?.country?.currency
          ),
      }}
    >
      <div className="page inter d-flex main-layout">
        <NavBar />
        <div className="layout d-flex flex-column">
          <Header />
          <div className="layout-content">
            <Routes>
              <Route
                path={path.root}
                element={
                  user.brand ? (
                    <Creators />
                  ) : user.store ? (
                    <Stores />
                  ) : user.staff ? (
                    <Home />
                  ) : (
                    <BaseHome />
                  )
                }
              />
              <Route path={"/dev"} element={<Dev />} />
              <Route path={path.store.root} element={<Stores />} />
              <Route path={path.iCartOperating.root} element={<Operators />} />
              <Route path={path.creators.root} element={<Creators />} />
              <Route path={path.prepRoom.root} element={<Kitchens />} />
              <Route path={path.suppliers.root} element={<Suppliers />} />
              <Route path={path.inventory.root} element={<Inventory />} />
              <Route path={path.materials.root} element={<Materials />} />
              <Route path={path.support.root} element={<Support />} />
              <Route path={path.wallet.root} element={<Wallet />} />
              <Route path={path.accounts.root} element={<Accounts />} />
              <Route path={path.wallet.root} element={<Wallet />} />
              <Route path={path.sales.root} element={<Sales />} />
              <Route path={path.business.root} element={<Businesses />} />
              <Route path={path.auth.logout} element={<Logout />} />
              <Route path={path.management.root} element={<Management />} />
              <Route
                path={path.conceptAndCreators.root}
                element={<ConceptsAndCreators />}
              />
              <Route path={path.all} element={<NotFound />} />
            </Routes>
          </div>
        </div>
      </div>
    </AppContext.Provider>
  );
}

export default Base;
