import axiosClient from "../../api/index.js";
import store from "../../utils/store.js";
import urls from "../../api/urls.js";
import { useState } from "react";
import { toast } from "react-toastify";

const useAPI = () => {
  const [errorResponse, setErrorResponse] = useState(null);
  const [loading, setLoading] = useState(false);

  // Check if the token is expired
  const isTokenExpired = (expiryTime) => {
    if (!expiryTime) return true;
    const currentTime = Date.now();
    return currentTime > expiryTime;
  };

  // Refresh the token if it's expired
  const refreshToken = async () => {
    try {
      const { refreshToken } = store.getTokens();

      if (!refreshToken) {
        store.removeAll();
        return null;
      }
      const response = await axiosClient.post(
        urls.auth.access,
        {},
        {
          headers: {
            "x-refresh-token": refreshToken,
          },
        }
      );
      const {
        accessToken,
        refreshToken: newRefreshToken,
        accessExpiry,
        refreshExpiry,
      } = response.data.data.tokens;

      // Update the tokens in store
      store.setTokens({
        accessToken,
        refreshToken: newRefreshToken,
        accessExpiry,
        refreshExpiry,
      });

      return accessToken;
    } catch (error) {
      console.error("Failed to refresh token", error);
      store.removeAll();
      return null;
    }
  };

  // Function to make API requests
  const makeRequest = async (method, url, data = null, headers = {}) => {
    try {
      setLoading(true);
      let { accessToken, accessExpiry } = store.getTokens();

      if (accessToken && isTokenExpired(accessExpiry)) {
        await refreshToken();
        accessToken = store.getTokens().accessToken;
      }

      const config = {
        url,
        headers: {
          "x-access-token": accessToken,
          ...headers,
        },
      };

      let response;
      switch (method) {
        case "get":
          response = await axiosClient.get(url, config);
          break;
        case "post":
          response = await axiosClient.post(url, data, config);
          break;
        case "put":
          response = await axiosClient.put(url, data, config);
          break;
        case "patch":
          response = await axiosClient.patch(url, data, config);
          break;
        case "delete":
          response = await axiosClient.delete(url, config);
          break;
        default:
          throw new Error(`Unsupported request method: ${method}`);
      }
      return response.data;
    } catch (error) {
      const message = error.response?.data?.message || error.message;
      setErrorResponse(error.response);
      console.log(error.response);
      toast.warn(message);
      return { error, data: null };
    } finally {
      setLoading(false);
    }
  };

  // API functions
  const get = (url, headers) => makeRequest("get", url, null, headers);
  const post = (url, data, headers) => makeRequest("post", url, data, headers);
  const put = (url, data, headers) => makeRequest("put", url, data, headers);
  const fdelete = (url, headers) => makeRequest("delete", url, null, headers);
  const patch = (url, data, headers) =>
    makeRequest("patch", url, data, headers);

  return {
    get,
    post,
    put,
    patch,
    fdelete,
    loading,
    setLoading,
    refreshToken,
    errorResponse,
  };
};

export default useAPI;
