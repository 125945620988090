import { useState } from "react";
import useAPI from "./useAPI";
import urls from "../../api/urls";
import { addParamsToUrl } from "../../utils/helpers";

function useWallet(props) {
  const [wallet, setWallet] = useState({ balance: 0 });
  const [transactions, setTransactions] = useState([]);
  const { get, loading, post } = useAPI();

  const getMyWallet = async () => {
    const { data, error } = await get(urls.wallet.me);
    if (error) return;
    setWallet(data.wallet);
  };

  const initTopUp = async (amount, cb) => {
    const { error, data } = await post(urls.wallet.fund, { amount });
    if (error) return;
    if (typeof cb === "function") cb();
    window.open(data.checkoutUrl, "_blank");
  };

  const creditWallet = async (body, cb) => {
    const { error, data } = await post(urls.wallet.adminFund, body);
    if (error) return;
    if (typeof cb === "function") cb();
  };

  const debitWallet = async (body, cb) => {
    const { error, data } = await post(urls.wallet.adminDebit, body);
    if (error) return;
    if (typeof cb === "function") cb();
  };

  const getTransactions = async (pageNumber = 1, limit = 10, account) => {
    const { data, error } = await get(
      addParamsToUrl(urls.wallet.transactions, { pageNumber, limit, account })
    );
    if (error) return;
    setTransactions(data.transactions);
  };

  return {
    loading,
    wallet,
    initTopUp,
    creditWallet,
    debitWallet,
    getMyWallet,
    transactions,
    getTransactions,
  };
}

export default useWallet;
