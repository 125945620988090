import DashboardIcon from "../../../assets/icons/DashboardIcon";
import { CardWithIcon } from "../../../components/Card";
import Revenue from "../../../components/charts/Revenue";

function Home(props) {
  return (
    <div>
      <div className="d-flex tab-column flex-wrap gap-10">
        <div className="full-flex">
          <div className="cards d-flex">
            <CardWithIcon
              icon={<DashboardIcon color="var(--main-yellow)" />}
              // className="info-light"
              subTitle="New Tasks"
            />
            <CardWithIcon
              icon={<DashboardIcon color="var(--main-yellow)" />}
              // className="warn-light"
              subTitle="Pending Tasks"
            />
          </div>
          <br />
          <Revenue title="Performance" />
        </div>
        <div style={{ minWidth: "30%" }}>
          <div className="card activities">
            <h2 className="fs-20 text-primary f-600">Todo</h2>
            <br />
            <span className="fs-14">You have no todo!</span>
          </div>
          <br />
          <div className="card activities">
            <h2 className="fs-20 text-primary f-600">Activities</h2>
          </div>
        </div>
      </div>
      <br />
    </div>
  );
}

export default Home;
