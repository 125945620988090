import AuthContext from "../../contexts/AuthContext";
import store from "../../utils/store.js";
import { useContext } from "react";
import useAPI from "./useAPI";
import urls from "../../api/urls";

const useProfile = () => {
  const { get, loading, errorResponse } = useAPI();
  const { setUser } = useContext(AuthContext);

  const restoreLocalUser = () => {
    let profile = store.getProfile();
    if (!profile) return null;
    return profile;
  };

  const restoreUser = async () => {
    if (!store.getTokens()?.accessToken) return;
    const { data, error } = await get(urls.profile.me);
    if (error) return;
    const { profile, brand, supplier, staff } = data;
    setUser({ ...profile, brand, supplier, staff });
  };

  return {
    restoreLocalUser,
    errorResponse,
    restoreUser,
    loading,
  };
};

export default useProfile;
