import avatar from "../assets/avatar.png";
import WithLink from "./table/WithLink";
import InfoText from "./TextInfo";

function Creator({ text, image = avatar, label = "Creator" }) {
  return (
    <div className="d-flex align-center">
      <img
        style={{ width: 40, height: 40, borderRadius: 25, marginRight: 5 }}
        src={image}
        alt="avatar"
      />
      <InfoText
        style={{ borderBottomWidth: 0 }}
        className="full-flex"
        title={`${label}:`}
      >
        <WithLink text={text} />
      </InfoText>
    </div>
  );
}

export default Creator;
