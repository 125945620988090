import { useState } from "react";
import Modal from "./Modal";
import Button from "./Button";
import useModal from "../hooks/useModal";
import Input from "./Input";
import store from "../utils/store";
import useSubscriptions from "../hooks/api/useSubscriptions";

function DeviceSetup(props) {
  const [purchaseCode, setPurchaseCode] = useState(null);
  const { isOpened, closeModal } = useModal(!store.getItem("outlet"));
  const { validateSubscription, loading } = useSubscriptions();

  const validate = () => validateSubscription(purchaseCode, closeModal);

  return (
    <Modal isVisible={isOpened}>
      <h2 className="fs-24 raleway f-700 text-primary">Setup Device</h2>
      <br />
      <Input
        value={purchaseCode}
        onChange={setPurchaseCode}
        placeholder="Purchase Code"
        title="Purchase Code"
      />
      <br />
      <div className="d-flex gap-10">
        <Button onClick={closeModal} width={100} title="Cancel" outline />
        <Button
          onClick={validate}
          loading={loading}
          disabled={!purchaseCode}
          title="Save"
        />
      </div>
    </Modal>
  );
}

export default DeviceSetup;
