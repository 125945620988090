import { useContext, useState } from "react";
import Group from "../FlexGroup";
import Button from "../Button";
import Input from "../Input";
import AppContext from "../../contexts/AppContext";
import FormattedInput from "../FormattedInput";
import urls from "../../api/urls";

const Region = ({
  city,
  menu = [],
  cities,
  id,
  activationCost,
  handleChange,
  remove,
}) => (
  <>
    <div className="submit-menu-card">
      <div className="inputs gap-10">
        <Input
          select
          options={cities.map((_) => ({ label: _.name, value: _._id }))}
          value={city}
          placeholder="City"
          onChange={(v) => handleChange(id, "city", v)}
        />
        <FormattedInput
          value={activationCost}
          placeholder="Activation Cost"
          onChange={(v) => handleChange(id, "activationCost", v)}
        />
      </div>
      <br />
      {/* {menu.map()} */}
      <br />
      <Button
        outline
        style={{ marginLeft: "auto" }}
        onClick={remove}
        borderColor="var(--alerts-danger)"
        title="Remove"
        color="danger-text"
        fontSize="fs-12"
        width={80}
        height={30}
      />
    </div>
    <br />
  </>
);

const AddButton = (props) => (
  <Button
    {...props}
    style={{ marginLeft: "auto" }}
    title="Add City"
    fontSize="fs-12"
    width={80}
    borderRadius={3}
    height={30}
  />
);

function Activation({ addDataToConcept, concept, loading, onFinished, id }) {
  const [regions, setRegions] = useState(
    concept
      ? concept?.concept?.regions?.map((_, idx) => ({
          id: idx + 1,
          city: _.city._id,
          activationCost: _.activationCost,
        }))
      : []
  );
  const { cities } = useContext(AppContext);

  const handleActivation = async () => {
    const response = await addDataToConcept(
      id,
      {
        regions: regions.map((_) => ({
          city: _.city,
          activationCost: _.activationCost,
          active: true,
        })),
      },
      urls.business.concepts.regions
    );
    if (response) onFinished(response);
  };

  return (
    <div>
      <Group
        menu={concept.menu}
        cities={cities}
        AddElement={AddButton}
        items={regions}
        setItems={setRegions}
        Element={Region}
        title="Available Cities"
      />
      <br />
      <Button
        loading={loading}
        onClick={handleActivation}
        title="Active Concept"
        disabled={!regions.length}
      />
    </div>
  );
}

export default Activation;
