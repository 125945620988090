const urls = {
  // auth: {
  //   signup: "/v2/admin/auth/signup",
  //   login: "/v2/admin/auth/login",
  //   access: "/v2/admin/auth/restore-access",
  //   resetPassword: "/v2/auth/reset-password",
  // },
  auth: {
    signup: "/v2/auth/signup",
    login: "/v2/auth/login",
    access: "/v2/auth/restore-access",
    resetPassword: "/v2/auth/reset-password",
    roles: {
      baseUrl: "/v2/auth/roles",
      permissions: "/v2/auth/permissions",
    },
  },
  profile: {
    me: "/v2/profile/me",
  },
  inventory: {
    products: {
      all: "/v2/inventory/products",
      history: "/v2/inventory/products/[id]/history",
    },
    categories: {
      all: "/v2/inventory/categories/",
    },
    materials: {
      all: "/v2/inventory/materials",
      cost: "/v2/inventory/materials/:id/cost",
      search: "/v2/inventory/materials/search?searchKey=",
    },
    requestPrep: "/v2/inventory/prep-requests",
    requestSupply: "/v2/inventory/supply-requests",
    requestMaterials: "/v2/inventory/materials-requests",
    sendDelivery: "/v2/inventory/supply-requests/:id/send-items",
    acceptSupply: "/v2/inventory/supply-requests/:id/approve-items",
    confirmDelivery: "/v2/inventory/supply-requests/:id/accept-items",
  },
  files: {
    baseUrl: "/v1/files/",
  },
  brands: {
    baseUrl: "/v2/business/brands",
    approve: "/v2/business/brands/:id/approve",
    updateStatus: "/v2/business/brands/:id/status",
  },
  business: {
    concepts: {
      baseUrl: "/v2/business/concepts",
      details: "/v2/business/concepts/:id",
      myConcepts: "/v2/business/concepts/me",
      grade: "/v2/business/concepts/:id/grade",
      submit: "/v2/business/concepts/:id/submit",
      regions: "/v2/business/concepts/:id/regions",
      status: "/v2/business/concepts/:id/status",
      extras: "/v2/business/concepts/:id/extras",
      addMenu: "/v2/business/concepts/:id/menu",
      addMachines: "/v2/business/concepts/:id/machines",
      packaging: "/v2/business/concepts/:id/packaging",
      addIngredients: "/v2/business/concepts/:id/ingredients",
    },
    subscriptions: {
      baseUrl: "/v2/subscriptions",
      validate: "/v2/subscriptions/validate",
    },
    menu: {
      baseUrl: "/v2/business/menu",
      cost: "/v2/business/menu/cost",
    },
    suppliers: {
      baseUrl: "/v2/business/suppliers",
      status: "/v2/business/suppliers/:id/active",
    },
  },
  wallet: {
    me: "/v2/wallet/me",
    baseUrl: "/v2/wallet",
    fund: "/v2/wallet/fund",
    adminFund: "/v2/wallet/admin-fund",
    adminDebit: "/v2/wallet/admin-debit",
    transactions: "/v2/wallet/transactions?",
  },
  accounts: {
    baseUrl: "/v2/accounts",
    search: "/v2/accounts/search",
  },
  departments: {
    baseUrl: "/v2/departments",
  },
  locations: {
    baseUrl: "/v2/locations",
  },
  outlets: {
    baseUrl: "/v2/operation-outlets",
  },
  staffs: {
    baseUrl: "/v2/staffs",
  },
  configs: {
    baseUrl: "/v2/configs",
    cities: "/v2/configs/cities",
    units: "/v2/configs/measures",
    countries: "/v2/configs/countries",
  },
  store: {
    create: "/v2/profile/create-store",
    requests: {
      get: "/v2/supplies/requests",
      accept: "/v2/supplies/requests/:id/responses",
    },
  },
  operations: {
    orders: "v2/operations/orders",
    kioskOrder: "v2/operations/orders/kiosks",
    acceptOrder: "/v2/operations/orders/:id/accept-order",
    completeOrder: "/v2/operations/orders/:id/complete-order",
  },
};

export default urls;
