import { useEffect, useState } from "react";
import Status from "../../../components/Status";
import ID from "../../../components/table/ID";
import Table from "../../../components/table/Table";
import WithLink from "../../../components/table/WithLink";
import useModal from "../../../hooks/useModal";
import useOutlets from "../../../hooks/api/useOutlets";
import usePagination from "../../../hooks/usePagination";
import PlusIcon from "../../../assets/icons/PlusIcon";
import OutletProfile from "../../../components/management/OutletProfile";
import Button from "../../../components/Button";
import Modal from "../../../components/Modal";

const tableHead = [
  {
    title: "City",
    target: "location.city.name",
  },
  {
    title: "Location",
    target: "location.label",
    render: (v) => <WithLink text={v} />,
  },
  {
    title: "Type",
    target: "type",
  },
  {
    title: "Active",
    target: "active",
    render: (v) => (
      <Status text={v?.toString()} light className={v ? "success" : "danger"} />
    ),
  },
  // {
  //   title: "Status",
  //   target: "status",
  //   render: (v) => (
  //     <Status
  //       className={
  //         v === "occupied"
  //           ? statuses.placed
  //           : v === "active"
  //           ? statuses.active
  //           : statuses.inactive
  //       }
  //       text={v}
  //     />
  //   ),
  // },
];

function Outlets(props) {
  const { closeModal, openModal, isOpened } = useModal();
  const [selectedOutlet, setSelectedOutlet] = useState(null);
  const [activatingOutlet, setActivatingOutlet] = useState(null);
  const {
    outlets,
    createOutlet,
    totalCount,
    updateOutlet,
    loading,
    getOutlets,
  } = useOutlets();

  const { limit, pageNumber, setPageNumber } = usePagination({
    initialLimit: 5,
  });
  const refresh = () => {
    closeModal();
    getOutlets(pageNumber, limit);
    setActivatingOutlet(null);
  };
  const handleSave = (data) => {
    const cb = () => {
      refresh();
      closeModal();
    };
    if (selectedOutlet) updateOutlet(selectedOutlet?._id, data, cb);
    else createOutlet(data, cb);
  };

  useEffect(() => {
    refresh();
    // eslint-disable-next-line
  }, [pageNumber, limit]);
  return (
    <div>
      <Modal
        closeModal={() => setActivatingOutlet(null)}
        isVisible={activatingOutlet}
      >
        <h2 className="fs-24 raleway f-700 text-primary">
          {activatingOutlet?.active ? "Deactivate" : "Activate"} Operations
        </h2>
        <br />
        <p>
          {activatingOutlet?.active ? "Deactivate" : "Activate"}{" "}
          <strong>{activatingOutlet?.name}</strong> in{" "}
          {activatingOutlet?.location?.city?.name}
        </p>
        <br />
        <div className="d-flex gap-10">
          <Button
            outline
            width={120}
            title="Cancel"
            onClick={() => setActivatingOutlet(null)}
          />
          <Button
            loading={loading}
            onClick={() => {
              updateOutlet(
                activatingOutlet?._id,
                { active: !activatingOutlet?.active },
                refresh
              );
            }}
            title="Continue"
          />
        </div>
      </Modal>
      <OutletProfile
        onSave={handleSave}
        isOpened={isOpened}
        closeModal={(v) => {
          setSelectedOutlet(null);
          closeModal();
        }}
        loading={loading}
        selectedOutlet={selectedOutlet}
      />
      <Table
        btn1={{
          icon: <PlusIcon />,
          title: "Add New Outlet",
          onClick: () => {
            openModal();
            setSelectedOutlet(null);
          },
        }}
        loading={loading}
        data={outlets}
        limit={limit}
        onPaginate={setPageNumber}
        totalCount={totalCount}
        head={[
          {
            title: "#",
            target: "#",
          },
          {
            title: "Name",
            target: "*",
            render: (value) => (
              <ID
                onClick={() => {
                  setSelectedOutlet(value);
                  openModal();
                }}
                id={value.name}
                label="Edit Outlet"
              />
            ),
          },
          ...tableHead,
          {
            title: "Action",
            target: "*",
            render: (v) => (
              <Button
                onClick={() => setActivatingOutlet(v)}
                width={160}
                height={40}
                fontSize="fs-12"
                className={!v.active ? "bg-info" : "bg-danger"}
                title={`${!v.active ? "Activate" : "Deactivate"} Operations`}
              />
            ),
          },
        ]}
        title="Offices, Stores & Prep Rooms"
      />
    </div>
  );
}

export default Outlets;
