import { Form, FormInput, Submit } from "../../components/form";
import {
  completeResetPasswordSchema,
  resetOTPSchema,
  resetPasswordSchema,
} from "../../utils/validators/auth";
import logo from "../../assets/logo-black.png";
import useAuth from "../../hooks/api/useAuth";
import Footer from "../../components/Footer";
import { Link, useSearchParams } from "react-router-dom";
import { path } from "../../utils/path";
import "../../css/pages/auth.css";
import Button from "../../components/Button";
import { otpOptions } from "../../utils/vars";

function ForgotPassword(props) {
  const [searchParams, setSearchParams] = useSearchParams();
  const { loading, resetPassword, requestResetPassword } = useAuth();

  const requestOTP = () =>
    requestResetPassword({
      email: searchParams.get("email"),
      method: otpOptions.email,
    });

  const handleSubmitEmail = ({ email }) => {
    setSearchParams({ email });
  };

  const continueWithEmail = () => {
    setSearchParams({
      email: searchParams.get("email"),
      method: otpOptions.email,
    });
    requestOTP();
  };

  const continueToReset = ({ otp }) => {
    setSearchParams({
      email: searchParams.get("email"),
      method: otpOptions.email,
      otp,
    });
  };

  const completeResetPassword = ({ password }) => {
    resetPassword(
      {
        email: searchParams.get("email"),
        otp: searchParams.get("otp"),
        password,
      },
      () => (window.location = "/login")
    );
  };

  return (
    <div className="auth-page inter d-flex flex-column">
      <div className="form d-flex form-container flex justify-center align-center">
        <div className="form d-flex flex-column form-container flex justify-center align-center">
          <img src={logo} alt="iCart Africa" />
          <br />
          <br />
          <div className="form-card shadow">
            <h2 className="fs-24 f-700 text-center text-primary">
              Forgot Password
            </h2>
            <br />
            <br />
            {searchParams.get("email") &&
            searchParams.get("method") &&
            searchParams.get("otp") ? (
              <Form
                validationSchema={completeResetPasswordSchema}
                onSubmit={completeResetPassword}
                initialValues={{
                  password: "",
                  password2: "",
                }}
              >
                <span className="fs-14">Create new password</span>
                <br />
                <br />
                <FormInput name="password" placeholder="Password" />
                <br />
                <FormInput name="password2" placeholder="Confirm Password" />
                <br />
                <Submit loading={loading} title="Continue" />
              </Form>
            ) : searchParams.get("email") && searchParams.get("method") ? (
              <Form
                validationSchema={resetOTPSchema}
                onSubmit={continueToReset}
                initialValues={{
                  otp: "",
                }}
              >
                <span className="fs-14">
                  We've sent and OTP to:{" "}
                  <span className="fs-14 f-600">
                    {searchParams.get("email")}
                  </span>{" "}
                </span>
                <br />
                <br />
                <FormInput name="otp" placeholder="OTP" type="number" />
                <br />
                <Submit loading={loading} title="Continue" />
                <br />
                <Button
                  style={{ borderWidth: 0 }}
                  onClick={requestOTP}
                  outline={true}
                  title="Resend OTP"
                />
              </Form>
            ) : !searchParams.get("method") && searchParams.get("email") ? (
              <div>
                <span className="fs-14">Choose where to recieve OTP: </span>
                <div className="btns">
                  <Button onClick={continueWithEmail} title="Email" outline />
                  <Button disabled title="SMS" outline />
                </div>
              </div>
            ) : (
              <Form
                validationSchema={resetPasswordSchema}
                onSubmit={handleSubmitEmail}
                initialValues={{
                  email: "",
                }}
              >
                <FormInput name="email" placeholder="Email" />
                <br />
                <Submit loading={loading} title="Continue" />
                <br />
                <span className="fs-14 f-400 text-center d-block">
                  Already have an account?{" "}
                  <Link to={path.auth.login} className="f-700 text-primary">
                    Login Here
                  </Link>
                </span>
              </Form>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ForgotPassword;
