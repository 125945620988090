import Dashboard from "../pages/app/accounts/Dashboard";
import { Route, Routes } from "react-router-dom";
import { path } from "../utils/path";
import AccountDetails from "../pages/app/accounts/AccountDetails";

function Accounts(props) {
  return (
    <Routes>
      <Route
        path={path.accounts.users.details.root}
        element={<AccountDetails />}
      />
      <Route path={path.all} element={<Dashboard />} />
    </Routes>
  );
}

export default Accounts;
