import { useState } from "react";
import "../css/components/input.css";
import Icon from "./Icon";
import LockIcon from "../assets/icons/LockIcon";
import Loader from "./Loader";

function Input({
  width = "100%",
  height = 48,
  password,
  type = "text",
  borderColor = "var(--yellow-tints-2)",
  borderWidth = 1,
  placeholder,
  rIcon,
  onBlur,
  onFocus,
  textarea,
  fontSize = "fs-14",
  value,
  onChange,
  errorMessage,
  select,
  options = [],
  placeHolderBg = "#fff",
  loading = false,
  ...props
}) {
  const [isTextVisible, setIsTextVisible] = useState(password ? false : true);
  const [isFocused, setIsFocused] = useState(false);
  const handleBlur = () => {
    setIsFocused(false);
    if (typeof onBlur === "function") onBlur();
  };
  const handleFocus = () => {
    setIsFocused(true);
    if (typeof onFocus === "function") onFocus();
  };

  const handleChange = (e) => {
    if (typeof onChange === "function") onChange(e.target.value);
  };

  const toggleVisible = () => {
    setIsTextVisible(!isTextVisible);
  };
  return (
    <div
      style={{
        height: textarea ? "auto" : height,
        width,
        borderColor,
        borderWidth,
      }}
      className={`input-container ${
        textarea ? "textarea" : ""
      } d-flex align-center ${isFocused || value ? "focused" : ""}`}
    >
      <Loader loading={loading} />
      {!select && placeholder && (
        <span
          style={{ color: borderColor, backgroundColor: placeHolderBg }}
          className={`d-block placeholder ${fontSize} ${
            isFocused || value ? "f-500" : "f-400"
          }`}
        >
          {placeholder}
        </span>
      )}
      {select ? (
        <select value={value} onChange={handleChange}>
          <option disabled>{placeholder}</option>
          {options.map((o, idx) => (
            <option key={idx.toString()} value={o.value}>
              {o.label}
            </option>
          ))}
        </select>
      ) : textarea ? (
        <textarea
          {...props}
          onChange={handleChange}
          value={value}
          autoComplete="off"
          onFocus={handleFocus}
          onBlur={handleBlur}
          className={`${fontSize} ${
            isFocused || value ? "f-500" : "f-400"
          } inter`}
        ></textarea>
      ) : (
        <input
          {...props}
          type={isTextVisible || !password ? type : "password"}
          onChange={handleChange}
          value={value}
          autoComplete="off"
          onFocus={handleFocus}
          onBlur={handleBlur}
          className={`${fontSize} ${isFocused || value ? "f-500" : "f-400"}`}
        />
      )}
      {password ? (
        <Icon onClick={toggleVisible}>
          <LockIcon />
        </Icon>
      ) : (
        rIcon
      )}
    </div>
  );
}

export default Input;
