export const path = {
  all: "*",
  root: "/",
  new: "/new",
  edit: "/edit",
  params: "/:params",
  details: "/details",
  auth: {
    root: "/auth/*",
    login: "/login",
    logout: "/logout",
    signUp: "/sign-up",
    forgotPassword: "/forgot-password",
  },
  inventory: {
    root: "/inventory/*",
    url: "/inventory",
    requests: {
      root: "/requests",
      url: "/inventory/requests",
      view: {
        root: "/requests/details",
        url: "/inventory/requests/details",
      },
    },
  },
  materials: {
    root: "/materials/*",
    url: "/materials",
    new: {
      url: "/materials/new",
    },
    edit: {
      url: "/materials/edit",
    },
    categories: {
      path: "/categories",
      url: "/materials/categories",
    },
  },
  conceptAndCreators: {
    root: "/concepts/*",
    url: "/concepts",
    brands: {
      root: "/brands",
      url: "/concepts/brands",
    },
    creators: {
      root: "/creators",
      url: "/concepts/creators",
    },
  },
  sales: {
    root: "/sales/*",
    url: "/sales",
  },
  wallet: {
    root: "/wallet/*",
    url: "/wallet",
  },
  store: {
    root: "/store/*",
    url: "/store",
  },
  suppliers: {
    root: "/suppliers/*",
    url: "/suppliers",
    requests: {
      root: "/requests",
      url: "/suppliers/requests",
      view: {
        root: "/requests/details",
        url: "/suppliers/requests/details",
      },
    },
  },
  prepRoom: {
    root: "/prep-room/*",
    url: "/prep-room",
  },
  creators: {
    root: "/creators/*",
    url: "/creators",
    new: {
      path: "/new-concept",
      url: "/creators/new-concept",
    },
    edit: {
      path: "/edit-concept",
      url: "/creators/edit-concept?id=",
    },
  },
  iCartOperating: {
    root: "/icart-operating/*",
    url: "/icart-operating",
    sales: "/icart-operating/sales",
    recordSale: {
      path: "/record-sale",
      url: "/icart-operating/record-sale",
    },
    onlineOrders: {
      path: "/online-orders/*",
      url: "/icart-operating/online-orders",
      new: {
        path: "/new",
        url: "/icart-operating/online-orders/new",
      },
      processing: {
        path: "/processing",
        url: "/icart-operating/online-orders/processing",
      },
      completed: {
        path: "/completed",
        url: "/icart-operating/online-orders/completed",
      },
      total: {
        path: "/total",
        url: "/icart-operating/online-orders/total",
      },
      details: {
        path: "/online-orders/details",
        url: "/icart-operating/online-orders/details",
      },
    },
  },

  accounts: {
    root: "/accounts/*",
    url: "/accounts",
    users: {
      root: "/users",
      url: "/accounts/users",
      details: {
        root: "/users/details",
        url: "/accounts/users/details",
      },
    },
    staffs: {
      root: "/staffs",
      url: "/accounts/staffs",
    },
    departments: {
      root: "/departments",
      url: "/accounts/departments",
    },
    roles: {
      root: "/roles",
      url: "/accounts/roles",
    },
  },
  business: {
    root: "/business/*",
    url: "/business",
    view: {
      path: "/view-business",
      url: "/business/view-business",
    },
    new: {
      root: "/new",
      url: "/business/new",
    },
    deactivated: {
      root: "/deactivated",
      url: "/business/deactivated",
    },
    purchased: {
      root: "/purchased",
      url: "/business/purchased",
    },
  },
  management: {
    root: "/management/*",
    url: "/management/",
    icarts: {
      root: "/icarts",
      url: "/management/icarts",
      view: {
        path: "/view-icart",
        url: "/management/view-icart",
      },
    },
    locations: {
      root: "/locations",
      url: "/management/locations",
    },
    outlets: {
      root: "/outlets",
      url: "/management/outlets",
    },
    layouts: {
      root: "/layouts",
      url: "/management/layouts",
    },
    cities: {
      root: "/cities",
      url: "/management/cities",
      add: {
        root: "/add-city",
        url: "/management/add-city",
      },
    },
    suppliers: {
      root: "/suppliers",
      url: "/management/suppliers",
    },
    countries: {
      root: "/countries",
      url: "/management/countries",
    },
  },
  support: {
    root: "/support/*",
    url: "/support",
    videos: {
      path: "/videos",
      url: "/support/videos/",
    },
    compliance: {
      path: "/compliance",
      url: "/support/compliance/",
    },
    complaints: {
      path: "/complaints",
      url: "/support/complaints/",
    },
  },
};
