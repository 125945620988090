import { useContext, useEffect, useState } from "react";
import Cash from "../../../assets/icons/Cash";
import PlusIcon from "../../../assets/icons/PlusIcon";
import Button from "../../../components/Button";
import Card from "../../../components/Card";
import AppContext from "../../../contexts/AppContext";
import Transactions from "../../../components/table/withdata/Transactions";
import useWallet from "../../../hooks/api/useWallet";
import Modal from "../../../components/Modal";
import useModal from "../../../hooks/useModal";
import FormattedInput from "../../../components/FormattedInput";

function Dashboard(props) {
  const { wallet, getMyWallet, loading, initTopUp } = useWallet();
  const { closeModal, openModal, isOpened } = useModal();
  const [topUpAmount, setTopUpAmount] = useState(0);
  const { formatCurrency } = useContext(AppContext);

  useEffect(() => {
    getMyWallet();

    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <Modal isVisible={isOpened} closeModal={closeModal}>
        <h2 className="fs-24 raleway f-700 text-primary">Top Up Wallet</h2>
        <br />
        <br />
        <FormattedInput
          onChange={setTopUpAmount}
          value={topUpAmount}
          placeholder="Enter amount"
        />
        <br />
        <div className="d-flex justify-end gap-10 align-center">
          <Button onClick={closeModal} width={110} outline title="Cancel" />
          <Button
            disabled={!topUpAmount}
            width={110}
            loading={loading}
            onClick={() => initTopUp(topUpAmount, closeModal)}
            title="Continue"
          />
        </div>
      </Modal>
      <div className="cards wallet-cards d-flex">
        <div style={{ flex: 1 }} className="bordered">
          <span className="sp d-block fs-14 f-400 raleway">
            Available Balance
          </span>
          <br />
          <span className="sp d-block text-brown fs-28 f-600">
            {formatCurrency(wallet.balance)}
          </span>
          <span className="fs-11 f-700 text-brown">
            Ledger Balance: {formatCurrency(wallet.ledgerBalance)}
          </span>
          <br />
          <div style={{ width: "100%" }} className="btns flex-wrap wallet-btns">
            <Button lSvg={<PlusIcon />} onClick={openModal} title="Top Up" />
            <Button lSvg={<Cash />} title="Withdraw" outline />
          </div>
        </div>
        <Card
          formatTitle={false}
          title={formatCurrency(0)}
          style={{ flex: 0.4 }}
          subTitle="Total Spent"
          className="justify-center align-center warn-light"
        />
        <Card
          formatTitle={false}
          className="justify-center align-center success-light"
          title={formatCurrency(wallet.balance)}
          style={{ flex: 0.4 }}
          subTitle="Money In"
        />
        <Card
          formatTitle={false}
          title={formatCurrency(wallet.balance * 1.6)}
          style={{ flex: 0.4 }}
          className="justify-center align-center info-light"
          subTitle="Transaction Volume"
        />
      </div>
      <br />
      <Transactions />
    </div>
  );
}

export default Dashboard;
