import useSubscriptions from "../../../hooks/api/useSubscriptions";
import usePagination from "../../../hooks/usePagination";
import { baseStatuses } from "../../../utils/statuses";
import { formatDateTime } from "../../../utils/date";
import React, { useEffect } from "react";
import WithImage from "../WithImage";
import WithLink from "../WithLink";
import Status from "../../Status";
import Table from "../Table";
import ID from "../ID";
import View from "../View";
import { path } from "../../../utils/path";

const tableHead = [
  {
    title: "#",
    target: "#",
  },
  {
    title: "iCart",
    target: "purchaseCode",
    render: (id) => (
      <ID link={path.business.view.url} id={id} label="View Details" />
    ),
  },
  {
    title: "Customer",
    target: "customer",
    render: (user) => (
      <WithImage
        text={`${user.firstName} ${user.lastName}`}
        imageUrl={user.profileImage}
      />
    ),
  },
  {
    title: "Primary Brand",
    target: "brands",
    render: (barands = []) => {
      const primary = barands.find((_) => _.isPrimary);
      return primary ? <WithLink text={primary.brand.name} /> : "-";
    },
  },
  {
    title: "Location",
    target: "location",
    render: (location) => <WithLink text={location?.label} />,
  },
  //   {
  //     title: "City",
  //     target: "city",
  //   },
  //   {
  //     title: "Status",
  //     target: "id",
  //     render: (v) => (
  //       <Status text="active" className="success-light success-text" />
  //     ),
  //   },

  {
    title: "Status",
    target: "status",
    render: (v) => <Status className={baseStatuses[v]} text={v} light />,
  },
  {
    title: "Date",
    target: "createdAt",
    render: (v) => formatDateTime(v).dateTime,
  },
  {
    title: "Actions",
    target: "*",
    render: (v) => (
      <View
        title="View More"
        link={path.management.icarts.view.url + `?id=${v._id}`}
        state={v}
      />
    ),
  },
];

function Subscriptions({ title }) {
  const { limit, pageNumber, setPageNumber } = usePagination({
    initialLimit: 5,
  });
  const { susbcriptions, totalCount, getSubscriptions, loading } =
    useSubscriptions();

  useEffect(() => {
    getSubscriptions(pageNumber, limit);

    // eslint-disable-next-line
  }, [pageNumber, limit]);

  return (
    <Table
      loading={loading}
      onPaginate={setPageNumber}
      limit={limit}
      totalCount={totalCount}
      head={tableHead}
      data={susbcriptions}
      title={title}
    />
  );
}

export default Subscriptions;
