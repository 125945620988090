import useAPI from "./useAPI";
import urls from "../../api/urls";
import { toast } from "react-toastify";
import { objectToFormData } from "../../utils/array";
import useProfile from "./useProfile";
import { useState } from "react";
import { addParamsToUrl } from "../../utils/helpers";

const useSupply = () => {
  const [totalCount, setTotalCount] = useState(0);
  const [supplyrequests, setSupplyRequests] = useState([]);
  const [supplyrequest, setSupplyRequest] = useState([]);
  const { post, loading, get, refreshToken } = useAPI();
  const { restoreUser } = useProfile();

  const getSupplyRequests = async (pageNumber = 1, limit = 10) => {
    const { data, error } = await get(
      addParamsToUrl(urls.store.requests.get, { pageNumber, limit })
    );
    if (error) return;
    setSupplyRequests(data.supplyRequests);
    setTotalCount(data.pagination.total);
  };

  const getSupplyRequest = async (id) => {
    const { data, error } = await get(urls.store.requests.get + `/${id}`);
    if (error) return;
    setSupplyRequest(data.supplyRequest);
  };

  const registerStore = async (fdata, img, cb) => {
    fdata = objectToFormData(fdata);
    fdata.append("logo", img);
    const { data, error, message } = await post(urls.store.create, fdata);
    if (error || !data) return;
    toast.info(message);
    await refreshToken();
    await restoreUser();
    if (typeof cb === "function") cb();
  };

  const acceptSupplyRequest = async (id, fdata, cb) => {
    const { data, message, error } = await post(
      urls.store.requests.accept.replace(":id", id),
      fdata
    );
    if (error) return alert(error.response.data.message);
    toast.success(message);
    if (typeof cb === "function") cb();
    return data;
  };

  return {
    acceptSupplyRequest,
    getSupplyRequests,
    getSupplyRequest,
    registerStore,
    supplyrequests,
    supplyrequest,
    totalCount,
    loading,
  };
};

export default useSupply;
