import urls from "../../api/urls";
import useAPI from "./useAPI";
import { useState } from "react";
import { addParamsToUrl } from "../../utils/helpers";
import { toast } from "react-toastify";

function useAccounts(props) {
  const [pagination, setPagination] = useState({ total: 0 });
  const [allAccounts, setAllAccounts] = useState([]);
  const [account, setAccount] = useState(null);
  const { loading, get } = useAPI();

  const getAllAccounts = async (pageNumber = 1, limit = 10) => {
    const { data, error } = await get(
      addParamsToUrl(urls.accounts.baseUrl, { pageNumber, limit })
    );
    if (error) return;
    setAllAccounts(data.users);
    setPagination(data.pagination);
  };

  const getAccount = async (id) => {
    const { data, error } = await get(urls.accounts.baseUrl + `/${id}`);
    if (error) return;
    setAccount(data.user);
  };

  const searchAccoount = async (searchKey, limit) => {
    if (!searchKey) return setAllAccounts([]);
    const { data, error, errorMEssage } = await get(
      addParamsToUrl(urls.accounts.search, { searchKey })
    );
    if (error) return toast.warn(errorMEssage);
    setAllAccounts(data);
  };

  return {
    loading,
    account,
    pagination,
    getAccount,
    allAccounts,
    getAllAccounts,
    searchAccoount,
  };
}
export default useAccounts;
