import { Route, Routes } from "react-router-dom";
import DashboardIcon from "../../../assets/icons/DashboardIcon";
import { CardWithIcon } from "../../../components/Card";
import Tab from "../../../components/Tab";
import NewOrders from "./New";
import ProcessingOrders from "./Processing";
import CompletedOrders from "./Completed";
import TotalOrders from "./Total";
import { path } from "../../../utils/path";

const tabs = [
  {
    title: "New Orders",
    to: path.iCartOperating.onlineOrders.new.url,
    path: path.iCartOperating.onlineOrders.new.path,
    element: <NewOrders />,
  },
  {
    title: "Processing Orders",
    to: path.iCartOperating.onlineOrders.processing.url,
    path: path.iCartOperating.onlineOrders.processing.path,
    element: <ProcessingOrders />,
  },
  {
    title: "Completed Orders",
    to: path.iCartOperating.onlineOrders.completed.url,
    path: path.iCartOperating.onlineOrders.completed.path,
    element: <CompletedOrders />,
  },
  {
    title: "Total Orders",
    to: path.iCartOperating.onlineOrders.total.url,
    path: path.iCartOperating.onlineOrders.total.path,
    element: <TotalOrders />,
  },
];

function Orders(props) {
  return (
    <div>
      <div className="cards d-flex">
        <CardWithIcon
          title={0}
          icon={<DashboardIcon color="var(--main-yellow)" />}
          // className="warn-light"
          subTitle="Total Orders"
        />
        <CardWithIcon
          title={0}
          icon={<DashboardIcon color="var(--main-yellow)" />}
          // className="warn-light"
          subTitle="Completed Orders"
        />
        <CardWithIcon
          title={0}
          icon={<DashboardIcon color="var(--main-yellow)" />}
          // className="warn-light"
          subTitle="New Orders"
        />
        <CardWithIcon
          title={0}
          icon={<DashboardIcon color="var(--main-yellow)" />}
          // className="warn-light"
          subTitle="Processing Orders"
        />
      </div>
      <br />
      <Tab canNavigate autoSelect tabs={tabs} />
      <Routes>
        {tabs.map((_, idx) => (
          <Route key={idx} path={_.path} element={_.element} />
        ))}
      </Routes>
    </div>
  );
}

export default Orders;
