import { Route, Routes } from "react-router-dom";
import { path } from "../utils/path";
import Dashboard from "../pages/app/conceptAndMenus/Dashboard";

function ConceptsAndCreators(props) {
  return (
    <Routes>
      <Route path={path.all} element={<Dashboard />} />
    </Routes>
  );
}

export default ConceptsAndCreators;
