import { useContext, useEffect, useState } from "react";
import Card from "../../../components/Card";
import useInventory from "../../../hooks/api/useInventory";
import usePagination from "../../../hooks/usePagination";
import MaterialsContext from "../../../contexts/MaterialsContext";
import { path } from "../../../utils/path";
import Table from "../../../components/table/Table";
import WithImage from "../../../components/table/WithImage";
import Status from "../../../components/Status";
import statuses from "../../../utils/statuses";
import View from "../../../components/table/View";
import PlusIcon from "../../../assets/icons/PlusIcon";
import { useNavigate } from "react-router-dom";

const tableHead = [
  {
    title: "Item",
    target: ["imageUrl", "name"],
    render: (values) => <WithImage text={values[1]} imageUrl={values[0]} />,
  },
  {
    title: "Price",
    target: "price",
  },
  {
    title: "Measure",
    target: "unitOfMeasure",
  },
  {
    title: "Life Span",
    target: "lifeSpanInDays",
    render: (v) => `${v} days`,
  },
  {
    title: "Category",
    target: "category.name",
  },
  {
    title: "Tags",
    target: "tags",
    render: (v) => v.join(", "),
  },
  {
    title: "Approved",
    target: "approved",
    render: (v) => (
      <Status
        text={v ? "approved" : "not approved"}
        className={`${v ? statuses.success : statuses.danger}-light ${
          v ? statuses.success : statuses.danger
        }-text`}
      />
    ),
  },
  {
    title: "Action",
    target: "*",
    render: (v) => (
      <View link={`${path.materials.url}/details?id=${v._id}`} state={v} />
    ),
  },
];

function Dashboard(props) {
  const { getMaterials, searchMaterial, materials, pagination, loading } =
    useInventory();
  const { pageNumber, limit, setPageNumber } = usePagination({});
  const [search, setSearch] = useState("");
  const { categories } = useContext(MaterialsContext);
  const navigate = useNavigate();

  const navigateNew = () => navigate(path.materials.new.url);

  useEffect(() => {
    getMaterials(pageNumber, limit);

    // eslint-disable-next-line
  }, [pageNumber, limit]);

  useEffect(() => {
    searchMaterial(search);
    // eslint-disable-next-line
  }, [search]);

  return (
    <div>
      <div className="cards d-flex">
        <Card
          className="success-light"
          title={pagination.total}
          subTitle="Active Products"
        />
        <Card className="danger-light" subTitle="Inactive Products" />
        <Card title={pagination.total} subTitle="Total Products" />
        <Card
          to={path.materials.categories.url}
          subTitle="Total Categories"
          title={categories.length}
        />
      </div>
      <br />
      <Table
        search={search}
        onSearch={setSearch}
        title="Raw Materials"
        limit={limit}
        loading={loading}
        totalCount={pagination.total}
        onPaginate={setPageNumber}
        data={materials}
        head={tableHead}
        btn1={{
          icon: <PlusIcon />,
          title: "New Material",
          onClick: navigateNew,
        }}
      />
    </div>
  );
}

export default Dashboard;
